import React from "react"
import NuvoImage from "../../components/NuvoImage"

const ImageStrip = () => {
  const images = [
    "LDS/DEV/homepage-strip-1",
    "LDS/DEV/homepage-strip-2",
    "LDS/DEV/homepage-strip-3",
    "LDS/DEV/homepage-strip-4",
    "LDS/DEV/homepage-strip-5"
  ]
  return (
    <div className="home-image-strip">
      {images.map((img, i) => (
        <div key={i}>
          <NuvoImage
            useAR
            cloudName="nuvolum"
            publicId={img}
            width="auto"
            responsive></NuvoImage>
        </div>
      ))}
    </div>
  )
}

export default ImageStrip
